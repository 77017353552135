import React from 'react';

import GenerateReportAccordion from '@presenters/web/components/GenerateReportAccordion';

import { columnWiseReportData } from '@utils/memberShipFoundationDistrictReport';

import { GetSitecoreReportQuery } from '@typings/operations';

export const ReportAccordion = ({
  sitecoreReportsData,
  reportHeaderTKeyValue,
}: {
  sitecoreReportsData: GetSitecoreReportQuery['reportData'];
  reportHeaderTKeyValue: string;
}) => {
  const columnWiseData = columnWiseReportData(sitecoreReportsData);

  return (
    <div className="flex flex-col gap-6">
      <h2 className="text-naturals-gray-600 font-semibold text-3lg mobile-m:text-xl tablet:text-xl-40 leading-lgx mobile-l:leading-lg-43 desktop:leading-xl-48 mb-0">
        {reportHeaderTKeyValue}
      </h2>
      <div className="flex flex-col w-full tablet:gap-15">
        {columnWiseData.map((reportsArray, index) => (
          <div
            key={`section-${index + 1}`}
            className="w-full flex flex-col tablet:flex-row tablet:gap-15"
          >
            {reportsArray.map(report => (
              <div
                key={report.label}
                className="w-full tablet:w-1/2 flex flex-col gap-2 mt-8 last:mb-8 first:mt-0 tablet:mb-0 tablet:mt-0"
              >
                <div className="text-naturals-gray-600 text-lg tablet:text-lg-24 font-semibold leading-md-30 desktop:leading-lg-36">
                  {report.label}
                </div>
                <GenerateReportAccordion items={report.children} />
              </div>
            ))}
            {reportsArray.length % 2 !== 0 && (
              <div className="w-full tablet:w-1/2 flex flex-col" />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
